.bgImage{
   background-image: url('../../../src/assets/Banner.png') !important;
   background-position: 50% 100%;
   background-repeat: no-repeat;
   background-size: cover;
   height: 100vh;
}

.bgImage1{
   background-image: url('../../../src/assets/Banner\ \(1\).png') !important;
   background-position: 50% 100%;
   background-repeat: no-repeat;
   background-size: cover;
   height: 100vh;
}
