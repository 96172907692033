.prostoOne {
   font-family: 'prosto One', sans-serif;
 }
 .z-index{
  z-index: 999;
 }
 .bg-light-me{
  background-color: #ffffff9d;
 }
 .w-60{
  width: 60% !important;
 }
 .w-35{
  width: 35%;
 }
 .logo{
  width: 85%;
}
@media (max-width: 768px) {
  .logo{
    width: 86% !important;
  }
  .width-2{
    width: 3% !important;
  }
}
 @media (max-width: 991px){
  .bannerBgColor{
    background: rgba(0, 0, 0, 0.488);
    height: 110vh;
    width: 100%;
  }
  .width-2{
    width: 5% !important;
  }
  .marginSmall{
   margin-top: 100px !important;
   margin-left: 0px;
 }
 .text-whiteMe{
  color: white;
 }
 .navBar{
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.w-90{
width: 90%;
}
.paddingBottom-me{
  padding-bottom: 50px;
}
 }

 @media (min-width: 992px){
 .margin{
  margin-left: 50px;
}

}
.p-me{
  padding: 50px !important;
  padding: 50px !important;}
@media (max-width: 575px) {
  .m-small{
    width: 20px;
  }
  .margin-small{
    margin-left: 50px !important;
    margin-right: 50px !important;
  }
  .w-100-me{
    width: 100%;
  }
  .p-me{
    padding: 40px !important;
  }
  .slider-container{
    width: 75%;
    height:50px
  }
}

.paddingSlider{
  padding-left: 50px ;
  padding-right: 50px;
}

@media (max-width: 575px) {
  .paddingSlider{
 display: flex;
 justify-content: center;
 align-items: center;
  }
  .slider-me{
    padding-left: 80px !important;
  }

}

.width-2{
  width: 2%;
}
.width-3{
  width: 32px;
}