.bgImage{
   background-image: url('../../../src/assets/WhatsApp\ Image\ 2024-07-10\ at\ 4.24.53\ PM.jpeg') !important;
   background-position: 50% 40%;
   background-repeat: no-repeat;
   background-size: cover;
   height: 110vh;
}

.bgImage2{
   /* background-color: #09c; */
   background-image: url('../../../src/assets/Ellipse.png') !important;
   /* background-position:25% 25%  ; */
   background-repeat: no-repeat;
   background-size: cover;
   /* height: 75vh; */
   width: 98vw;
}
.positionRelative{
   position: relative;
}
.positionAbsolute{
   position: absolute;
   top: 75%;
}
@media (max-width : 991px) {
   .positionAbsolute{
      position: absolute;
      top: 80%;
   }
   .bgImage2{
      width: 100vw;
   }

}
.positionA{
   position: absolute;
   bottom: 50%;
/* left: 15% */
}