.bgImage{
   background-image: url('../../../src/assets/Photo\ \(4\).png') !important;
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
   height: 100vh;
}

.bgImage1{
   background-image: url('../../../src/assets/Backgorund.png') !important;
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
   /* height: 30vh; */
}

