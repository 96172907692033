.bgImage{
   background-image: url('../../../src/assets/Newsletter.png') !important;
   background-position: center;
   background-repeat: no-repeat;
   background-size: contain;
   height: 30vh;
}
.bgImage1{
   background-image: url('../../../src/assets/Banner\ \(2\).png') !important;
   background-position: 50% 100%;
   background-repeat: no-repeat;
   background-size: cover;
   height: 100vh;
}

@media (max-width: 992px) {
   .bgImage{
      background-size: cover;
      height: 50vh;
      width: 100%;
   }
   .contact{
      padding-left: 50px;
      padding-right: 50px;
   }
}